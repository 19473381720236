* {
    box-sizing: border-box;
}

document,
window,
body {
    width: 100vw !important;
    height: 100vh !important;
    overflow: hidden !important;
}

a {
    color: inherit;
    text-decoration: inherit;
}

*::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 8px;
}

*::-webkit-scrollbar-button {
    display: none;
}

